import { Injectable } from '@angular/core';
import { OktaConfig } from '@okta/okta-angular';

import { Observable, BehaviorSubject } from 'rxjs';
import { APPLICATION_CONFIGURATION } from '../config/config';
import { CryptoService } from './crypto.service';

export interface IAppConfig {
  oktaConfiguration?: OktaConfig;
  appConfiguration?: any;
}

@Injectable({providedIn: 'root'})
export class AppConfigService {
  private configSource: BehaviorSubject<IAppConfig> = new BehaviorSubject<
  IAppConfig
  >({
    appConfiguration: undefined,
    oktaConfiguration: undefined
  });
  settings: Observable<Readonly<IAppConfig>>;

  constructor(private cryptoService: CryptoService) {
    this.settings = this.configSource.asObservable();
  }

  setConfig(config: Partial<IAppConfig>) {
    const updatedConfig = { ...this.configSource.value, ...config };
    this.configSource.next(updatedConfig);
    APPLICATION_CONFIGURATION.CONFIG = config.appConfiguration;
    const oktaConfig = config.oktaConfiguration;
    // oktaConfig.clientId = this.cryptoService.decrypt(oktaConfig.clientId);
    // oktaConfig.onSessionExpired = () => {};
    APPLICATION_CONFIGURATION.OKTA_CONFIG = <any>(oktaConfig);
  }

  getConfig(): Readonly<IAppConfig> {
    return this.configSource.value;
  }
}
