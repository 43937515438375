<div class="row text-center post-logout">
    <img src="app/shared/images/post-logout-bg.png" alt="Sync" class="bg-img" />
    <div class="signin-box">
        <img src="app/shared/images/coreo-logo-post-logout.png" alt="Sync" class="coreo-logo" />
        <div class="content">
            <p>This user has not been provisioned into Coreo View yet. Please contact administrator. 
               <br /> <br />Click button below to log out.</p>
            <button class="btn btn-primary mt-10" (click)="clickSignOut()">Logout</button>
        </div>
    </div>
</div>