import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { OktaCallbackComponent, OktaAuthGuard } from '@okta/okta-angular';

import { LogoutComponent } from './shared/components/logout.component';
import { PostLogoutComponent } from './shared/components/post-logout.component';
import { modulename } from './shared/config/modules';


const routes: Routes = [
  {
    path: 'implicit/callback',
    component: OktaCallbackComponent
  },
  {
    path: '',
    loadChildren: () => import('app/modules/layout/layout.module').then(m => m.LayoutModule)
  },
  {
    path: 'logout',
    component: LogoutComponent,
    canActivate: [OktaAuthGuard]
  },
  {
    path: 'post-logout',
    component: PostLogoutComponent
  },
  {
    path: 'user-onboard',
    loadChildren: () => import('app/modules/user-onboard/user-onboard.module').then(m => m.UserOnBoardModule)
  },
  {
    path: modulename.loginLayout,
    loadChildren: () => import('app/modules/user-login/user-login.module').then(m => m.UserLoginModule)
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],
  exports: [RouterModule],
  providers: []
})
export class AppRoutingModule { }
