import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { from, Observable, of, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { switchMap } from 'rxjs/operators';
import { HttpService } from '../services/http.service';
import { DataService } from '../services/data.service';
import { httpStatusCodes, POST_MSG, APPLICATION_CONFIGURATION } from '../config/config';
import { SessionService } from '../services/session.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  constructor(
    private httpService: HttpService,
    private dataService: DataService,
    private sessionService: SessionService
  ) { }

  addAuthHeader(request) {
    let authHeader;
    if (this.sessionService.isOktaLogin) {
      const tokenStorage = JSON.parse(localStorage.getItem('okta-token-storage'));
      authHeader = tokenStorage && tokenStorage.accessToken && tokenStorage.accessToken.accessToken;
    } else {
      const tokenStorage = JSON.parse(localStorage.getItem('token-storage'));
      authHeader = tokenStorage && tokenStorage.accessToken;
    }
    if (authHeader) {
      return request.clone({
        setHeaders: {
          Authorization: `Bearer ${authHeader}`
        }
      });
    } else {
      this.dataService.updateLogoutTrigger();
    }
    
    return request;
  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {

    if (request.url !== APPLICATION_CONFIGURATION.CONFIG.oktaCurrentSession && !request.url.includes('auntbertha')) {
      request = this.addAuthHeader(request);
    }

    if (request.method === 'PUT') {
      request.headers.append('If-Match', this.httpService.etag);
    }

    return next.handle(request).pipe(
      switchMap((response) => {
        const res = JSON.parse(JSON.stringify(response));
        if (res && res.status === 0) {
          this.dataService.updateUnknownHttpResponse();
          return throwError('error');
        }
        return of(response);
      }),
      catchError(error => {
        if (error.status === httpStatusCodes.unauthorized && !error.url.includes('auntbertha')) {
          return from(this.sessionService.getAccessToken()).pipe(
            switchMap((res) => {
              if (!this.sessionService.isRefeshTokenCall && res) {
                this.sessionService.isRefeshTokenCall = true;
                this.sessionService.storeAccessToken(res);
                setTimeout(() => {
                  this.sessionService.isRefeshTokenCall = false;
                }, 60000);
                this.sessionService.getCurrentSession();
              }
              request = this.addAuthHeader(request);
              return next.handle(request);
            }),
            catchError(err => {
              if (err.status === httpStatusCodes.unauthorized && !err.url.includes('auntbertha')) {
                this.dataService.updateLogoutTrigger();
              }
              return throwError(err);
            })
          );
        }
        if (error.status === httpStatusCodes.forbidden) {
          this.dataService.updateLogoutTrigger();
        }
        return throwError(error);
      })
    );
  }
}
