import { Injectable } from '@angular/core';
import {
  HttpClient
} from '@angular/common/http';
import { APPLICATION_CONFIGURATION } from '../../shared/config/config';

@Injectable()
export class HttpService {
  etag: any;
  config: any;

  constructor(private http: HttpClient) {
  }

  buildUrl(resourceUrl: any) {
    return APPLICATION_CONFIGURATION.CONFIG.baseUrl + resourceUrl;
  }

  buildCoreoUrl(resourceUrl: any) {
    return APPLICATION_CONFIGURATION.CONFIG.coreoUrl + resourceUrl;
  }

  buildGeopMapUrl(resourceUrl: any) {
    return APPLICATION_CONFIGURATION.CONFIG.baseUrl + resourceUrl;
  }

  get(url: string, responseText: boolean = false) {
    if (responseText) {
      // ToDo: Need to encorporate in services to send always response as json format. We are fixing this in 6th sep build
      return this.http.get(this.buildUrl(url), { responseType: 'text' });
    } else {
      return this.http.get(this.buildUrl(url));
    }
  }

  getCoreo(url: string) {
    return this.http.get(this.buildCoreoUrl(url));
  }

  getPdf(url: string) {
    return this.http.get(this.buildUrl(url), { responseType: 'blob' as 'json' });
  }

  postCoreo(url: string, data: any) {
    return this.http.post(this.buildCoreoUrl(url), data);
  }

  post(url: string, data: any, responseText: boolean = false) {
    if (responseText) {
      return this.http.post(this.buildUrl(url), data, { responseType: 'text' });
    } else {
      return this.http.post(this.buildUrl(url), data);
    }
  }

  delete(url: string) {
    return this.http.delete(this.buildUrl(url));
  }

  put(url: string, data: any, responseText: boolean = false) {
    if (responseText) {
      return this.http.put(this.buildUrl(url), data, { responseType: 'text' });
    } else {
      return this.http.put(this.buildUrl(url), data);
    }
  }

  getContent(url) {
    return this.http.get(url);
  }

  postContent(url, data) {
    return this.http.post(url, data);
  }

  getContentWithHeaders(url, headers) {
    return this.http.get(url, { headers: headers });
  }

  getWithCredentials(url) {
    return this.http.get(url, { withCredentials: true });
  }

  deleteWithCredentials(url) {
    return this.http.delete(url, { withCredentials: true });
  }
}
