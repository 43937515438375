import {
    Injectable,
    Inject
} from '@angular/core';
import {
    DataService
} from './data.service';
import { HttpService } from './http.service';
import * as moment from 'moment';
import { APPLICATION_CONFIGURATION } from '../config/config';
import { api } from '../config/api';

import { OktaAuthStateService, OKTA_AUTH } from '@okta/okta-angular';
import { AuthState, OktaAuth } from '@okta/okta-auth-js';
import { Observable, of } from 'rxjs';

@Injectable()
export class SessionService {
    isExistInUEE: boolean;
    lastInteractedTime = moment.utc();
    lastUpdatedTime: any;
    sessionExpireTime = moment.utc();
    interval: any;
    isOktaLogin: boolean;
    sessionExpiryDate: Date;
    isRefeshTokenCall: boolean;

    constructor(private dataService: DataService, private http: HttpService,        
    private _oktaAuthStateService: OktaAuthStateService,
    @Inject(OKTA_AUTH) private _oktaAuth: OktaAuth,) {
        this.isOktaLogin = true;
        this.isRefeshTokenCall = false;
    }

    checkIsLoggedInUser() {
        const currentUser = JSON.parse(localStorage.getItem('token-storage'));
        return currentUser && currentUser.accessToken;
    }

    getIsOktaLogin() {
        return this.http.get(api.GetIsOktaLogin);
    }

    storeAccessToken(tokens) {
        if (!this.isOktaLogin && tokens && tokens.accessToken) {
            const tokenStorage = JSON.parse(localStorage.getItem('token-storage'));
            tokenStorage.accessToken = tokens.accessToken;
            tokenStorage.refreshToken = tokens.refreshToken
            localStorage.setItem(
                'token-storage',
                JSON.stringify(tokenStorage)
            );
        }
    }

    getAccessToken() {
        if (!this.isRefeshTokenCall) {
            if (this.isOktaLogin) {
                return of(this._oktaAuth.getAccessToken()).toPromise();
            } else {
                const tokenStorage = JSON.parse(localStorage.getItem('token-storage'));
                var token = {
                    refreshToken: tokenStorage.refreshToken,
                    accessToken: tokenStorage.accessToken
                }
                return this.http.post(api.RefreshToken, token);
            }
        }
        return of(new Observable());
    }

    getOktaSession() {
        this.http.getWithCredentials(APPLICATION_CONFIGURATION.CONFIG.oktaCurrentSession).subscribe(
            (res: any) => {
                this.onExtendSession(res && res.expiresAt);
            },
            (err) => {
                if (err.status === 404) {
                    this.dataService.updateLogoutTrigger()
                }
                console.log(err);
            });
    }

    getCustomSession() {
        const tokenStorage = JSON.parse(localStorage.getItem('token-storage'));
        if (tokenStorage && tokenStorage.refreshToken) {
            let token = {
                refreshToken: tokenStorage.refreshToken
            }
            this.http.post(api.UpdateSession, token).subscribe(
                (res: any) => {
                    this.onExtendSession(res && res.sessionExpiryDateTime);
                },
                (err) => {
                    if (err.status === 404) {
                        this.dataService.updateLogoutTrigger()
                    }
                    console.log(err);
                });
        }
    }

    onExtendSession(expiryDate) {
        if (this.interval) {
            clearInterval(this.interval);
        }
        if (expiryDate && !this.isExistInUEE) {
            this.lastUpdatedTime = moment.utc();
            this.lastInteractedTime = moment.utc();
            this.sessionExpireTime = moment.utc(expiryDate);
            this.interval = setInterval(() => {
                if (this.lastInteractedTime.isAfter(this.lastUpdatedTime)) {
                    const differ = this.lastInteractedTime.diff(this.lastUpdatedTime , 'seconds');
                    this.lastUpdatedTime = this.lastInteractedTime;
                    this.sessionExpireTime.add(differ, 'seconds');
                } else {
                    const currentTime = moment.utc();
                    const diff = this.sessionExpireTime.diff(currentTime, 'seconds') - 60;
                    if (diff <= 0) {
                        this.dataService.updateTimeoutWarning(false);
                        clearInterval(this.interval);
                    }
                }
            }, 1000);
        }
    }

    getCurrentSession() {
        if (this.isOktaLogin) {
            this.getOktaSession();
        } else {
            this.getCustomSession();
        }
    }
}
