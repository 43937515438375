import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class DataService {
  private notifyObj$ = new Subject();
  private countObj$ = new Subject();
  private leftSelection$ = new Subject();
  private bbvSelection$ = new Subject();
  private bbvRefresh$ = new Subject();
  private paginationChanges$ = new Subject();
  private PatientGlobalSearch$ = new Subject();
  private showQuickAssignBtn$ = new Subject();
  private zoomChanges$ = new Subject();
  private clickPagination$ = new Subject();
  private signalrTrigger$ = new Subject();
  private availBedChange$ = new Subject();
  private stackViewChange$ = new Subject();
  private bedLocationChange$ = new Subject();
  private logoutTrigger$ = new Subject();
  private componentClickTrigger$ = new Subject();
  private globalSearch$ = new Subject();
  private isAppAccess$ = new Subject();
  private isUserInvalid$ = new Subject();
  private sessionTimeoutWarning$ = new Subject();
  private viewSyncData$ = new Subject();
  private unknownHttpResponse$ = new Subject();
  private apiErrorMessageShow$ = new Subject();
  private selectedFacilityReport$ = new Subject()

  globalSearchMpi: string;

  constructor() {
    this.globalSearchMpi = null;
  }

  sessionTimeoutWarning() {
    return this.sessionTimeoutWarning$;
  }

  updateTimeoutWarning(logout: boolean) {
    this.sessionTimeoutWarning$.next(logout);
  }

  getUserInvalid() {
    return this.isUserInvalid$;
  }

  updateUserInvalid(data: any) {
    this.isUserInvalid$.next(data);
  }

  getAppAccess() {
    return this.isAppAccess$;
  }

  updateAppAccess(data: any) {
    this.isAppAccess$.next(data);
  }

  getNotifyBedData() {
    return this.notifyObj$;
  }

  updateNotifyBedData(data: any) {
    this.notifyObj$.next(data);
  }

  getCountData() {
    return this.countObj$;
  }

  updateCountData(data: any) {
    this.countObj$.next(data);
  }

  changeLeftSelection() {
    return this.leftSelection$;
  }

  updateLeftSelection(data: any) {
    this.leftSelection$.next(data);
  }

  globalSearch(data: any) {
    this.globalSearch$.next(data);
  }

  getGlobalSearch() {
    return this.globalSearch$;
  }

  getBBVSelection() {
    return this.bbvSelection$;
  }

  updateBBVSelection(data: any) {
    this.bbvSelection$.next(data);
  }

  getBbvRefresh() {
    return this.bbvRefresh$;
  }

  updateBbvRefresh(data: any) {
    this.bbvRefresh$.next(data);
  }

  getPaginationChanges() {
    return this.paginationChanges$;
  }

  updatePaginationChanges(data: any) {
    this.paginationChanges$.next(data);
  }

  getPatientGlobalSearch() {
    return this.PatientGlobalSearch$;
  }

  updatePatientGlobalSearch(data) {
    this.PatientGlobalSearch$.next(data);
  }

  getShowQuickAssignBtn() {
    return this.showQuickAssignBtn$;
  }

  getZoomChanges() {
    return this.zoomChanges$;
  }

  updateZoomChanges(data: any) {
    this.zoomChanges$.next(data);
  }

  getAvailBedChange() {
    return this.availBedChange$;
  }

  changeAvailBed(data: any) {
    this.availBedChange$.next(data);
  }

  getChangeStackView() {
    return this.stackViewChange$;
  }

  changeStackView() {
    this.stackViewChange$.next(null);
  }

  getBedLocationChange() {
    return this.bedLocationChange$;
  }

  changeBedLocation(data: any) {
    this.bedLocationChange$.next(data);
  }

  clickPagination() {
    return this.clickPagination$;
  }

  updateClickPagination(data: any) {
    this.clickPagination$.next(data);
  }

  getSignalrTrigger() {
    return this.signalrTrigger$;
  }

  updateSignalrTrigger(data: any) {
    this.signalrTrigger$.next(data);
  }

  logoutTrigger() {
    return this.logoutTrigger$;
  }

  updateLogoutTrigger() {
    this.logoutTrigger$.next(null);
  }

  componentClickTrigger() {
    return this.componentClickTrigger$;
  }

  updateComponentClickTrigger() {
    this.componentClickTrigger$.next(null);
  }

  getViewSyncData() {
    return this.viewSyncData$;
  }

  updateViewSyncData(data: any) {
    this.viewSyncData$.next(data);
  }

  getUnknownHttpResponse() {
    return this.unknownHttpResponse$;
  }

  updateUnknownHttpResponse() {
    this.unknownHttpResponse$.next(null);
  }

  getApiErrorMessageShow() {
    return this.apiErrorMessageShow$
  }

  updateApiErrorMessageShow() {
    return this.apiErrorMessageShow$.next(null)
  }

  getFacilityReport() {
    return this.selectedFacilityReport$;
  }

  updateFacilityReport(data: any) {
    this.selectedFacilityReport$.next(data);
  }
}
