import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { routeUrl } from '../config/modules';

@Component({
    selector: 'app-logout',
    template: '<div></div>',
    styleUrls: [],
    
})
export class LogoutComponent {
  constructor(private router: Router) {
    this.router.navigate([routeUrl.bedboard]);
  }
}
