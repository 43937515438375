import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { routeUrl } from '../config/modules';
import { POST_MSG } from '../config/config';
import { DataService } from '../services/data.service';

@Component({
    selector: 'app-post-logout',
    templateUrl: '../views/post-logout.component.html',
    styleUrls: ['../styles/post-logout.component.less'],
    
})
export class PostLogoutComponent {
  constructor(private router: Router, private dataService: DataService) { }

  clickSignOut() {
    this.dataService.updateLogoutTrigger();
  }
}
