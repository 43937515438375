import {
  NgModule
} from '@angular/core';
import {
  CommonModule
} from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import {
  HttpService
} from '../services/http.service';
import { TokenInterceptor } from '../interceptors/token.interceptor';

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule
  ],
  providers: [
    HttpService,
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true }
  ]
})
export class HttpServiceModule { }
