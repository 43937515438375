<router-outlet></router-outlet>

<ng-template  #accessModal>
  <div class="modal-body">
    User is not assigned to the client application. Please Contact Administrator.
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="logout()">OK</button>
  </div>
</ng-template>

<ng-template  #responseErrorModal>
  <div class="modal-body">
    Please clear your browser cache and relaunch Coreo View to login.
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="logout()">OK</button>
  </div>
</ng-template>

<ng-template *ngIf="showApiErrorMessagePopup" style="z-index: 1399;" #apiErrorMessageShowModal>
  <div class="modal-body">    
    <div>Sorry, something went wrong. Please go back and try again.</div>
    <div>If the problem persists, contact Coreo View Administrator.</div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="clickOk()">OK</button>
  </div>
</ng-template>


<ng-template  id="initial-whitebkgnd" class="initial-loader bg-none"  #loadModal>

  <div class="modal-body" class="test">
    <div class="loader-block">
      <img src="./assets/loading-spinner-text.gif" />
    </div>
  </div>

</ng-template>
