export const datePickerConfig = {
  dateFormat: 'mm/dd/yyyy',
  height: '30px',
  width: '200px',
  selectionTxtFontSize: '13px'
};

export const configValues = {
  dateTimeFormat: 'MM/DD/YYYY hh:mm:ss A',
  dateFormat: 'MM/DD/YYYY',
  dateFormatymd: 'YYYY-MM-DD',
  timeFormat: 'hh:mm:ss A',
  monthFormat: 'MMMM',
  dateTimeCurrentFormat: 'MM/DD/YYYY HH:mm:ss',
  dateTimeWithMs: 'MM/DD/YYYY hh:mm:ss.SSS A'
};

export const defaultPipeDateConfig = {
  dateFormat: 'MM/dd/yyyy',
}

export const groupTypeConfig = {
  AC: 1,
  PAC: 2,
  CC: 3
};

export const httpStatusCodes = {
  etag: 412,
  notAcceptable: 406,
  forbidden: 403,
  unauthorized: 401,
  success: 200,
  badRequest: 400
};

export const uiPasswordTypes = {
  Onboarding: 1,
  ForgotPassword:  2,
  ExpiredPassword: 3,
  TemoraryPassword:  4, 
  Changepassword:  5,
}

export const listOfAllowedHttpStatusCodes = [400,401,403,412]

export const waitingTime = 10000;
export const updatePatientTimeZone = 600000;

export const eventKeyCodes = {
  keyDown: 40,
  keyUp: 38,
  keyLeft: 37,
  keyRight: 39,
  keyEnter: 13,
  keyTab: 9
};

export const imageKeyNames = [
  'bedIconPath1',
  'bedIconPath2',
  'patientIconPath1',
  'patientIconPath2',
  'patientIconPath3',
  'patientIconPath4',
  'patientIconPath5'
];

export const resolution = {
  normalResolution: 1280,
  hdReady: 1366,
  fullHD: 1920,
  reportsResolution: 1720
};

export const automationSources = {
  none: 103,
  coreo: 104
};

export const exceptions = {
  uniqueKeyException: 'SqlUniqueKeyException'
};

export const bedNames = {
  adt: 'ADT',
  home: 'HOME'
};

export const zoom = {
  default: 120
};

export const splCharPattern = {
  // eslint-disable-next-line
  splPatterns: "[ A-Za-z0-9-_',.&()]"
};

export const groupTypeNames = {
  cc: 'Cross Continuum'
};

export const configNames = {
  adtLocation: 'ADT Location',
  defaultTimezone: 'US/Mountain',
  noSpecific: 'No Specific Attributed Providers',
  noSpecificCohort: 'No Specified Cohort'
};

export const dispositionStates = {
  observation: 'OBS',
  admit: 'ADT',
  dischargePossible: 'POS',
  dischargePending: 'PND',
  dischargeComlete: 'COM',
  emergency: 'VIA ED',
  ppvObservation: 'OB',
  inPatient: 'IN'
};

export const automationEvent = {
  admitEventModuleId: 107,
  dischargeEventModuleId: 108,
  transferEventModuleId: 109,
  emergencyEventModuleId: 129,
  moduleName: 'Automation'
};

export const triState = {
  all: 'all',
  avail: 'avail',
  pts: 'pts'
};

export const errorMessages = {
  wrongAddress: 'WrongAddress'
};

export const Backspace = 8;

export const Default_Search_Text = null;

export const Space = 32;

export const Start_Numeric = 48;

export const End_Numeric = 57;

export const Start_Alphabet = 65;

export const End_Alphabet = 90;

export const minDuration = 1000;
export const summaryDuration = 2000;
export const assignMinDuration = 8000;
export const layoutDuration = 1920;
export const refreshTimeInterval = 5000;

export const defaultVal = {
  maxBed: 200,
  bedsPerColumn: 30,
  locMaxBed: 100
};

export const sourceValues = {
  coreo: 'Connected via Coreo',
  none: 'None'
};

export const headHightId = {
  headerName: 'header-name',
  headerText: 'header-text'
};

export const gridTextHeight = {
  height: 30
};
export const MONTH_ARRAY = [
  {
    id: 1,
    name: 'January'
  },
  {
    id: 2,
    name: 'February'
  },
  {
    id: 3,
    name: 'March'
  },
  {
    id: 4,
    name: 'April'
  },
  {
    id: 5,
    name: 'May'
  },
  {
    id: 6,
    name: 'June'
  },
  {
    id: 7,
    name: 'July'
  },
  {
    id: 8,
    name: 'August'
  },
  {
    id: 9,
    name: 'September'
  },
  {
    id: 10,
    name: 'October'
  },
  {
    id: 11,
    name: 'November'
  },
  {
    id: 12,
    name: 'December'
  }
];

export const ITRACKOR_REPORT_DEFAULT_YEAR = 2019;

export const MAX_LIMIT_YEARS = 50;

export const ITRACK_REPORT_ID = 9;

export const RISK_SCORE_MAX_LIMIT = 99;

export const PatientInnerHeight = 135;
export const PatientDeviceHeight = 474;
export const deviceInnerHeight = 143;
export const deviceHeight = 420;

export const defaultStateName = 'No State';

export const windowHeight = {
  headHeight: 95,
  sectionHeight: 530,
  gridHeight: 480,
  minHeight: 300,
  contentHeight: 590,
  tableWidth: 199,
  tableHeight: 505,
  fileLogGrid: 650,
  tabHeight: 46,
  EntriesGrid: 400,
  EntriesGridInnerHeight: 171 
};

export const fileExtensions = {
  pdf: '.pdf'
};

export const CV_CONFIG = {
  CV: 'cv',
  BBV: 'bbv',
  PPV: 'ppv',
  GMV: 'gmv'
};

export const USER_STATUS_CODES = {
  provisioned: 'P',
  onboarded : 'O',
  locked: 'L',
  deactivated: 'D',
  expired: 'E'
};

export const APPS_CONFIG = {
  CV: 'CV'
};

export const POST_MSG = {
  PATIENT_SYNC: 'patient_sync',
  CV_VIEW_CHANGE: 'cv_view_change',
  UEE_CV_VIEW_CHANGE: 'uee_cv_view_change',
  LOGOUT: 'logout',
  CHECK_IN_UEE: 'check_in_uee',
  CHECK_IN_CV: 'check_in_cv',
  EXTEND_SESSION: 'extend_session',
  CV_VIEW_SYNC: 'cv_view_sync',
  CV_SOURCE_APP: 'CV'
};

export const APPLICATION_CONFIGURATION = {
  CONFIG: {
    baseUrl: '',
    clientUrl: '',
    coreoUrl: '',
    oktaCurrentSession: '',
    showApiErrorMessagePopup: false,
    isCustomAuthentication: false
  },
  OKTA_CONFIG: {
    clientId: '',
    issuer: '',
    redirectUri: '',
    postLogoutRedirectUri: ''
  }
};

export const GEOMAP_DROPDOWN = [
  {id: 1, name: 'Search Patients'},
  {id: 2, name: 'Search Network'},
  {id: 3, name: 'Search All'}
];

export const DEFUALT_REPORT_FILTER_PERIOD = 3;

export const READMISSION_TABS = {
  allDischarges: 'All Discharges',
  allReadmissions: 'All Readmissions',
  byFacilityType: 'By Facility Type'
}

export const DATE_ERROR = 'Start Date should be less than End Date.';

export const FACILITY_REPORTS = {
  'patientVolume': 1,
  'averageLos': 2,
  'readmission': 3,
  'utilizationSummary': 4,
  'retentionSummary': 5,
  'readmissionAuditDashboard': 6
}

export const NONE_SELECTED = 'None Selected';

export const READMISSION_AUDIT_FILTER = [
  {
    id: 1,
    name: 'Readmission Audit Completed'
  },
  {
    id: 0,
    name: 'Readmission Audit Pending'
  }]

export const DEFAULT_CONTRACT = 'NoContract';

export const MAX_BED_ERROR_MSG = 'For the selected Facility / Location the maximum number of beds has been reached.'

export const PUBLIC_KEY = 
'-----BEGIN PUBLIC KEY-----' +
'MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAs6VZoO8w4s09nZrcBI9o' +
'znr683qOdjfhA2zryRVtx1Q2SmX0y3LGZws8Oq4+3jBPXmzvckkjoQ5UvEtko70y' +
'JH4NBolCMlpn5LH3olglGcu+UhORZbrk7dLyy5yy2FxVcziHJoMr5BkZw7MGPdkE' +
'KYcSWkMoCY3s7K8Y2sr1Yp3mvVTHE0PC7gOTiaVMiMVx5+vlC76MdrwjLLeOJdPX' +
'ExYkLCg/L2bwpuPDqFVHUFgqkKF7gtCeb/YvWGpVh3gd+fFHzyfn7w+7MbxqcQwQ' +
'+BMQ6CCTZlwW3HFWJPpbxmo0iV+cmUr6N+/HYKJ5ibKpgrhUSZtY4dPj/CbDZryw' +
'gwIDAQAB' +
'-----END PUBLIC KEY-----';

export const IdleTime = 1200000

export const BackToLoginTime = 5000