export const api = {
  GetModules: 'api/lookup/AutomationEvent',
  GetExceptionTypes: 'api/lookup/ExceptionType',
  RiskGroup: 'api/lookup/RiskGroup',
  GetAutomationLog: 'api/AutomationLog',
  GetExceptionLog: 'api/AutomationLog/GetDataSourceTranslation',
  GetPatientByMPI: 'api/patient/get-patient-by-mpi',
  GetEventAction: 'api/patient-disposition/get-event-action',
  GetGender: 'api/lookup/gender',
  SaveAutomationLog: 'api/patient-disposition/update-patient-disposition',
  GetLocations: 'api/location/group',
  GetBedNumbers: 'api/bed-to-location/find-beds-on-location',
  GetCurrentDate: 'api/common',
  ExceptionLogging: '',
  AddGroup: 'api/group',
  GetAllGroupTypes: 'api/lookup/groupType',
  GetStates: 'api/lookup/state',
  GetCities: 'api/common/city/city',
  GetZipCodes: 'api/common/city/zip',
  GetAutomationSources: 'api/lookup/AutomationSource',
  GetAttributedProviders: 'api/patient-roster/find-all-attributed-providers',
  GetMemberships: 'api/membership/get-master-memberships',
  GetFacilities: 'api/facility',
  UpdateGroup: 'api/group',
  GetGroupByPageNo: 'api/group',
  GetGroupById: 'api/group',
  AddLocation: 'api/location',
  LocationIdentifier: 'api/location/check-if-identifier-exist',
  UpdateLocation: 'api/location',
  GetLocationByPageNo: 'api/location',
  GetLocationById: 'api/location',
  AddPatient: 'api/patient',
  AddPatientCoreo: 'api/patient-coreo',
  AddManualPatient: 'api/patient-coreo/add-manual-patient',
  UpdateManualPatient: 'api/patient-coreo',
  UpdatePatient: 'api/patient',
  GetPatientByPageNo: 'api/patient',
  GetPatientById: 'api/patient',
  GetOrigin: 'api/group/Acute',
  GetMembershipList: 'api/membership/get-all-membership',
  GetPatientMembershipList: 'api/membership',
  GetMembershipListInFilter: 'api/membership/get-membership-by-userid/1',
  GetMembershipListInFilterMembership: 'api/membership/get-membership-by-userid/0',
  GetCohorts: 'api/cohort/find-all-patient-cohorts',
  GetCareTeam: 'api/themes/get-all-caremanagers',
  GetPcp: 'api/themes/get-all-providers',
  GetLevels: 'api/lookup/levellocation',
  GetGroupsByTypes: 'api/group',
  GetPatientAttributeById: 'api/patient-attribute',
  GetPatientAttributeByPageNo: 'api/patient-attribute',
  PatientAttribute: 'api/patient-attribute',
  UpdatePatientAttribute: 'api/patient-attribute',
  GetBedAttributeById: 'api/bed-attribute',
  GetBedtAttributeByPageNo: 'api/bed-attribute',
  AddBedtAttribute: 'api/bed-attribute',
  UpdateBedAttribute: 'api/bed-attribute',
  UpdateUserEula: 'api/user',
  GetUserAccessById: 'api/user-access',
  GetUserAccessByPageNo: 'api/user-access',
  AddUserAccess: 'api/user-access',
  UpdateUserAccess: 'api/user-access',
  GetUserRoleById: 'api/role',
  GetUserRoleByPageNo: 'api/role',
  AddUserRole: 'api/role',
  UpdateUserRole: 'api/role',
  GetAvailableAccessRights: 'api/role/find-all-permissions',
  GetAllUserRoles: 'api/role/find-all-roles',
  GetAllUserRosters: 'api/patient-roster/find-all-patient-rosters',
  GetAssignedUsers: 'api/role',
  AddBedToLocation: 'api/bed-to-location',
  ResetPassword: 'api/user',
  GetAvailableGroupLocations: 'api/themes/find-all-locations-groups/0',
  GetLocationByGroupId: 'api/location/group',
  GetAllUserAccess: 'api/access',
  GetAssignedAccessUsers: 'api/access',
  GetPatientActivityById: 'api/patient-activity',
  GetPatientActivityByPageNo: 'api/patient-activity',
  AddPatientActivity: 'api/patient-activity',
  UpdatePatientActivity: 'api/patient-activity',
  UpdateAssignBedToPatient: 'api/patient/PostAssignPatient',
  GetPatientActivityFields: 'api/common',
  Login: 'api/token',
  ResetPasswordLogin: 'api/auth',
  Logout: 'api/token/logout',
  SystemLogout: 'api/token/logout/System',
  UserLogout: 'api/token/logout/User',
  BedBoardView: 'api/bed-board-view',
  SessionTimeout: 'api/common/LoginTimeOut',
  getPatientByBedId: 'api/bed-description',
  GetBedDetailsByBedId: 'api/bed-to-location',
  getPatientBedAttributes: 'api/AssociateBedAttribute',
  savePatientBedAttributes: 'api/AssociateBedAttribute',
  getPatientDisposition: 'api/patient-disposition',
  getAlertTimeframes: 'api/lookup/AlertTimeframe',
  getPatientAdmitDate: 'api/patient/get-admit-discharge-datetime',
  addPatientDispostion: 'api/patient-disposition',
  updatePatientDispostion: 'api/patient-disposition',
  getDisposition: 'api/lookup/patientdisposition',
  GetBedAttribute: 'api/bed-attribute/true/null/null/1/10000/',
  GetPatientActivities: 'api/patient-activity/true/null/null/1/10000/',
  GetPatientAttributes: 'api/patient-attribute/true/null/null/1/10000/',
  GetPatientDispositions: 'api/lookup/patientdisposition',
  SaveTheme: 'api/themes',
  DeleteTheme: 'api/themes/delete-theme',
  GetThemes: 'api/themes',
  UpdateTheme: 'api/themes',
  UpdateDefaultTheme: 'api/themes/default-theme',
  ApplyFilter: 'api/themes/apply-filter',
  ApplyTheme: 'api/themes/apply-theme',
  getAllPatientAttributes: 'api/associate-patient-attribute/find-all-lookUps-attributes',
  getAllPatientActivities: 'api/associate-patient-activity/find-all-lookUps-activities',
  GetPatientActivitiesAttributes: 'api/associate-patient-activity',
  GetActivityStatus: 'api/lookup/ActivityStatus',
  GetResposibleRoles: 'api/role/find-all-roles',
  BmAddPatientActivities: 'api/associate-patient-activity',
  BmAddPatientAttributes: 'api/associate-patient-attribute',
  GetFilterGroupLocations: 'api/themes/find-all-locations-groups/1',
  GetLOS: 'api/patient-los',
  SaveLOS: 'api/patient-los',
  GetPathway: 'api/pathway/find-patient-pathway-header',
  SavePathway: 'api/pathway',
  GetAllPathways: 'api/pathway/true/null/null/1/1000',
  GetAllMilestoneBranches: 'api/pathway/find-mile-stone-branch',
  GetAllStatuses: 'api/lookup/MileStoneStatus',
  GetMilestones: 'api/pathway/find-patient-pathway-milestone',
  SaveMilestone: 'api/pathway',
  FindBedCount: 'api/themes/find-bed-count',
  SendMoveRequest: 'api/patient-move-request',
  InactiveUserStatus: 'api/user',
  InactivePatientStatus: 'api/patient',
  InactviveCheckStatus: 'api/patient',
  InactiveBedStatus: 'api/bed-to-location',
  InactiveBedDelete: 'api/bed-to-location',
  GetBedsByLocationId: 'api/user-access/find-beds-on-location',
  GetMoveRequestDetails: 'api/patient-move-request',
  CancelMove: 'api/patient-move-request/Delete-Request',
  AcceptMove: 'api/patient-move-request/accept',
  RejectMove: 'api/patient-move-request/reject',
  GetAuditLogByPageNo: 'api/auditLog',
  GetPathwaySummaryDetails: 'api/bed-description/get-pathway-details',
  GetAuditLogDropDown: 'api/auditLog',
  GetOriginGroups: 'api/group/FindGroupsForUser',
  GetOriginLocations: 'api/location/group',
  GetReportData: 'api/report',
  UpdateBed: 'api/bed-to-location',
  GetLogs: 'api/bed-description/bed-logs',
  GetUpdateBed: 'api/bed-board-view/bed-alert',
  UpdateBedboardNotification: 'api/bed-board-view/bbm-alert-notification',
  UpdatePatientInBedBoard: 'api/bed-board-view/patient-alert-notification',
  DownloadCSVLog: 'api/auditLog/download-audit-report',
  GetReportCSV: 'api/report/download-report',
  GetTimeZone: 'api/common/TimeZone',
  CheckPatientAleradyExistInBed: 'api/patient/check-patient-exist',
  GetVersion: 'api/common/BuildVersion',
  GetCurrentDateTime: 'api/common',
  CancelAcceptedMove: 'api/patient-move-request/cancel',
  GetByIdPathway: 'api/pathway',
  GetBedCleaningStatus: 'api/bed-cleaning',
  BbvTotalPages: 'api/bed-board-view/find-page-count',
  ThemeTotalPages: 'api/themes/theme-page-count',
  FilterTotalPages: 'api/themes/filter-page-count',
  SearchTotalPages: 'api/bed-board-view/patient-search-page-count',
  BbvPatientSearch: 'api/bed-board-view/patient-search',
  ForceMoveRequest: 'api/patient-move-request/force',
  SaveBedCleaningStatus: 'api/bed-cleaning',
  GetAllBedAttributes: 'api/AssociateBedAttribute/find-all-lookUps-bedattributes',
  BbvPaginationSize: 'api/common/MaxBedOnBBV',
  ZoomSettings: 'api/lookup/ZoomSetting',
  NextMilestone: 'api/pathway/close-pending-milestone',
  GetTimeZones: 'api/common/timezones',
  GetBedCurrentDateTime: 'api/bed-to-location/bed-location-time',
  HelpDocLink: 'api/common/HelpDocLink',
  GetPatientCurrentDateTime: 'api/patient/get-patient-current-timezone',
  GetMoveDestinationCount: 'api/common/MoveDestinationCount',
  GetNotifyDecrypt: 'api/bed-to-location/bed-module-info',
  GetMoveSelectedDstBeds: 'api/patient-move-request/selected-bed-count',
  GetNotifications: 'api/lookup/EmailAlert',
  AddRoster: 'api/patient-roster',
  UpdateRoster: 'api/patient-roster',
  GetRosterById: 'api/patient-roster',
  GetPatientSearch: 'api/patient/search-patient',
  GetPatientSearchNoncoreo: 'api/patient/search-noncoreo-patient',
  CheckMPIExist: 'api/patient/get-patient-using-mpi',
  getAutomationMembershipName: 'api/lookup/getAutomationMembershipName',
  CheckFacilityExist: 'api/Location/IsCoreoLocationExist',
  CheckFacilityMapped: 'api/facility/check-if-facility-mapped',
  GetRosterByPageNo: 'api/patient-roster',
  CheckMembershipListsExist: 'api/Membership/IsCoreoMemberShipExist',
  GetAllRosters: 'api/patient-roster',
  GetLosBranch: 'api/patient-los/get-all-los-branch',
  GetAutomationLogDataCount: 'api/AutomationLog/FindDataCount',
  GetAuditLogDataCount: 'api/auditLog/find-auditlog-count',
  GetCohortsByPatient: 'api/patient/get-associate-patient-cohorts',
  GetCareTeamByPatient: 'api/patient/get-associate-patient-caremanagers',
  GetMembershipsByPatient: 'api/patient/get-associate-patient-memberships',
  GetRostersByPatient: 'api/patient/get-associate-patient-rosters',
  GetCohortByPageNo: 'api/cohort',
  GetCohortById: 'api/cohort',
  UpdateCohort: 'api/cohort',
  SyncCohortToCoreo: 'api/cohort/sync-cohorts-manually',
  getFaceSheet: 'api/facesheet',
  GetPatientUsingMpi: 'api/patient/get-coreo-patient-using-mpi',
  SyncToCoreo: 'api/patient/Sync-patient',
  CoreoFlowPatientCount: 'api/cohort/get-patient-count-on-cohorts',
  CoreoPatientCount: 'api/cohort/get-coreo-patient-count-on-cohorts',
  BulkAssignData: 'api/cohort/patient-bulk-admit',
  GetBulkAdmit: 'api/common/bulkadmit',
  GetHighlightTimeframe: 'api/common/HighlightTimeframe',
  ActMdGuid: 'api/actmd/set-guid',
  CheckAddBedAvailable: 'api/bed-to-location/check-max-bed',
  GetRefreshTime: 'api/user/refresh-interval',
  GetPasswordExpiryDecrypt: 'api/user/decrypt-notify',
  GetRiskIndicator: 'api/lookup/RiskIdentifier',
  GetPrioritizedView: 'api/prioritized-view/get-prioritizedview-details',
  PriorityViewApplyTheme: 'api/prioritized-view/apply-theme',
  PriorityViewApplyFilter: 'api/prioritized-view/apply-filter',
  PriorityViewSearch: 'api/prioritized-view/prioritizedview-search',
  PriorityViewThemeTotalCount: 'api/prioritized-view/theme-page-count',
  PriorityViewFilterTotalCount: 'api/prioritized-view/filter-page-count',
  PriorityViewTotalCount: 'api/prioritized-view/page-count',
  PriorityViewSearchTotalCount: 'api/prioritized-view/prioritizedview-search-count',
  GeoMapApplyFilter: 'api/geomap/geomap-location-information',
  GeoMapApplyTheme: 'api/geomap/geomap-theme/',
  GeoMapSearch: 'api/geomap/geomap-search/',
  AddFacilityImage: 'api/group/geomap-image',
  GetImageById: 'api/geomap/geomap-image',
  GetGrpLocations: 'api/geomap/get-locationids?groupId=',
  GetCommunities: 'api/lookup/community',
  GetGeoMapCommunities: 'api/geomap/geomap-communities',
  GetCategoriesDetails: 'api/geomap/get-categories-details',
  GetGeoMapSelect: 'api/geomap/geomap-select/',
  SearchAttributedProviders: 'api/patient-roster/search-attributed-providers?search=',
  SearchCohorts: 'api/patient-roster/search-cohorts?search=',
  GetCohortsAll: 'api/patient-roster/search-cohorts?search=null',
  GetPrioritizedViewRefreshTime: 'api/common/PrioritizedViewRefreshInterval',
  GetLoginInfo: 'api/token/loginInfo',
  GetNetworkDetails: 'api/geomap/get-grouptype-details/GroupType',
  GetOktaUsers: 'api/auth/get-okta-users',
  GetPrioritizedViewPageSize: 'api/common/PrioritizedViewPageSize',
  FlagPatient: 'api/patient/flag-patient',
  AddFlaggedPatientAttributes: 'api/associate-patient-attribute/add-flag-patient-attribute',
  GetAppsByUser: 'api/unified-view/get-user-apps',
  GetPinnedApps: 'api/unified-view/get-pinned-applicaitons',
  GetLastLoginView: 'api/unified-view/get-lastlogin-appsdetails',
  GetPinnedPresets: 'api/unified-view/get-pinned-presets',
  GetAllPresets: 'api/unified-view/get-all-preset-details',
  GetPresetById: 'api/unified-view/get-preset-details-byid/',
  UpdateSelectedAppPreset: 'api/unified-view/save-lastlogin-appsdetails',
  UpdatePreset: 'api/unified-view/save-preset-details',
  UpdateToolbarPinning: 'api/unified-view/save-pinned-applications',
  getCVConfig: 'api/unified-view/get-Config-value/',
  GetAssociatePatientRisk: 'api/patient/get-associate-patient-Risk',
  SignalRCachingValue: 'api/bed-board-view/signalr-caching-value',
  GetDefaultZoomLevel: 'api/common/DefaultZoomLevel',
  GetSatelliteZoomLevel: 'api/common/SatelliteZoomLevel',
  GetInnerZoomLevel: 'api/common/InnerZoomLevel',
  GetAssureCarePatientUrl: 'api/auth/get-token-assurecare/',
  GetPPVStartDate: 'api/common/PPVStartDate',
  UEEHelpDocLink: 'api/common/UEEHelpDocLink',
  UpdateAppPosition: 'api/unified-view/update-application-position/',
  GetNationalStateServices: 'api/geomap/national-state-communities/',
  GetMaxGeomapMarkers: 'api/common/MaxGeoMapMarkers',
  GetAuntBerthaConfig: 'api/geomap/aunt-bertha-settings',
  GetGeomapPanChangeInterval: 'api/common/GeomapPanChangeInterval',
  GetMaxNationalStateCommunities: 'api/common/MaxNationalStateCommunities',
  Updatetimezones: 'api/common/Updatetimezones',
  GetNearestLOSDays: 'api/common/NearestLosDays',
  getDedupPatientDetails: 'api/patient/get-deduplication-details',
  GetPatientDetailsById: 'api/patient/get-patient-details',
  GetPrincipalDiagnosis: 'api/patient-los/get-principal-diagnosis',
  GetPatientVolumeCount: 'api/report/patient-volume-count',
  GetPatientVolumeTrendingReport: 'api/trendingchart/patient-volume',
  GetPatientVolumeDetails: 'api/report/patient-volume-drilldown',
  DownloadPatientVolumeCsv: 'api/report/download-patient-volume-report',
  DownloadPatientVolumeDataCsv: 'api/report/download-patient-volume-drilldown-report',
  DownloadAverageLosCsv: 'api/report/download-average-los-report',
  DownloadAverageLosDataCsv: 'api/report/download-average-los-drilldown-report',
  GetAverageLosTrendingReport: 'api/trendingchart/average-los',
  GetAverageLosReport: 'api/report/average-los',
  GetAverageLosDrilldown: 'api/report/average-los-drilldown',
  GetReadmissionBucket: 'api/readmissionrange/get-master-readmission-within-range',
  GetAllDischargesReport: 'api/report/discharge-summary',
  GetAllDischargesDrilldown: 'api/report/discharge-summary-drilldown',
  DownloadAllDischargesCsv: 'api/report/download-discharge-summary-Report',
  GetAllDischargesTrendingReport: 'api/trendingchart/discharge-summary/0',
  GetAllReadmissionsTrendingReport: 'api/trendingchart/discharge-summary/1',
  DownloadAllDischargesPatientDataCsv: 'api/report/download-discharge-summary-drilldown-report',
  GetAllReadmissionsReport: 'api/report/readmission-summary',
  GetAllReadmissionsDrilldown: 'api/report/readmission-summary-drilldown',
  DownloadAllReadmissionsPatientDataCsv: 'api/report/download-readmission-drilldown',
  DownloadAllReadmissionsCsv: 'api/report/download-readmission-summary-Report',
  GetByFacilityTypeReport: 'api/report/facilitywise-readmission-summary',
  GetByFacilityTypeDrilldown: 'api/report/facilitywise-readmission-drilldown',
  DownloadByFacilityTypePatientDataCsv: 'api/report/download-facilitywise-readmission-drilldown',
  DownloadByFacilityTypeCsv: 'api/report/download-facilitywise-readmission-report',
  GetUtilizationSummaryReport: 'api/report/utilization-summary',
  GetUtilizationSummaryTrendingReport: 'api/trendingchart/utilization-summary',
  GetUtilizationSummaryDrilldown: 'api/report/utilization-summary-drilldown',
  DownloadUtilizationSummaryPatientDataCsv: 'api/report/download-utilization-summary-drilldown',
  DownloadUtilizationSummaryCsv: 'api/report/download-utilization-summary',
  GetRetentionSummaryReport: 'api/report/network-retention-summary',
  GetRetentionSummaryTrendingReport: 'api/trendingchart/network-retention-summary',
  GetRetentionSummaryDrilldown: 'api/report/network-retention-drilldown',
  DownloadRetentionSummaryPatientDataCsv: 'api/report/download-network-retention-drilldown',
  DownloadRetentionSummaryCsv: 'api/report/download-network-retention-summary',
  GetReadmissionAuditLookup: 'api/readmissionauditlookup',
  GetReadmissionDetails:'api/readmissionaudit/get-readmission-details',
  GetReadmissionAuditDetails: 'api/readmissionaudit/get-readmission-audit-info',
  SaveReadmissionAuditDetails: 'api/readmissionaudit/save-readmission-audit-data',
  GetAuditCompletion: 'api/dashboardauditcompletion/get-dashboard-audit-completion',
  GetPrimaryDiagnosis: 'api/DashboardPrimaryDiagnosis/get-dashboard-primary-diagnosis',
  GetObtainMedication: 'api/dashboardobtainmedication/get-patient-able-to-obtain-medication',
  GetSocialFactors: 'api/dashboardsocialfactors/get-dashboard-Social-factors',
  GetIsObtainMedication: 'api/lookup/IsAbleToObtainMedication',
  GetMedicationReconciliation: 'api/lookup/IsMedicationReconciliation',
  GetDashboardDrilldownData: 'api/dashboardreadmissionchart/dashboard-chart-drilldown',
  DownloadDashboardDrilldownData: 'api/dashboardreadmissionchart/download-dashboard-chart-drilldown',
  GetReadmissionCategory: 'api/dashboardreadmissionchart/get-dashboard-readmission-category',
  GetRiskFactors: 'api/dashboardreadmissionchart/get-dashboard-risk-factors',
  GetChronicConditionsDropdown: 'api/readmissionauditlookup/get-chronic-conditions?search=',
  GetChronicConditions: 'api/dashboardreadmissionchart/get-dashboard-chronic-conditions',
  GetPatientStatusOnDischarge: 'api/dashboardreadmissionchart/get-dashboard-patient-functional-status',
  GetAttendedFollowUpVisit: 'api/dashboardreadmissionchart/get-dashboard-attended-followup-visit',
  GetVisitedEdPriorAdmission: 'api/dashboardreadmissionchart/get-dashboard-visited-ED-prior-admission',
  GetMedicationReconciliationChart: 'api/dashboardreadmissionchart/get-dashboard-medication-reconciliation',
  GetClientType: 'api/common/ClientType',
  GetMPIValue: 'api/common/MPI',
  GetMoveReasons: 'api/patient-move-request/get-move-reason',
  GetPVListPrintMaxCount: 'api/common/PPVPrintMaxCount',
  GetProvidersPrintMaxCount: 'api/common/StarRatingPrvdPrintMaxCount',
  SearchProviderListAddresses: 'api/starrating/get-provider-location',
  GetProviderSpecialties: 'api/starrating/get-provider-speciality',
  getProviderList: 'api/starrating/get-provider-details',
  GetEULAFileName: 'api/common/EULAFileName',
  BulkUploadPatient: 'api/patient/bulk-upload',
  ValidateEmail: 'api/user-onboard/email-link-verification/',
  ValidateUserDetails: 'api/user-onboard/validate-user',
  GetSecurityQuestions: 'api/user-onboard/get-security-questions',
  GetPasswordConfig: 'api/user-onboard/get-password-config',
  UserOnboardSubmit: 'api/user-onboard',
  GetIsOktaLogin: 'api/common/IsOktaIdP',
  CustomUser: 'api/customuser',
  User: 'api/auth',
  CustomUserResendInvite: 'api/customuser/resend-invite/',
  GetFileUploadLog: 'api/patient/uploaded-file-details',
  GetFileUploadGridRefresh: 'api/common/FileUploadGridResfreshInSec',
  GetFileLogClickEntries: 'api/patient/uploaded-patient-details/',
  UpdateSession: 'api/token/update-session',
  RefreshToken: 'api/token/refresh',
  CustomUserTemporaryPassword: 'api/customuser/generate-password/',
  ForgetPswGetSecurityQuestions: 'api/user-onboard/get-user-security-questions/',
  ValidateAnswers: 'api/user-onboard/validate-securityquestions',
  UpdatePassword: 'api/user-onboard/update-password',
  UpdateSecurityQuestionAnswers: 'api/customuser/update-security-question-answers',
  BulkUploadFileName: 'api/common/BulkUploadTemplateName'
};
