import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { OktaAuthGuard } from '@okta/okta-angular';
import { routeUrl } from '../config/modules';
import { SessionService } from './session.service';

@Injectable()
export class AuthGuard  {

  constructor(public router: Router, 
    private sessionService: SessionService, private oktaAuthGuard: OktaAuthGuard) {}

  canActivate(_route: ActivatedRouteSnapshot, _state:           
    RouterStateSnapshot): any {
        if (this.sessionService.isOktaLogin) {
            return this.oktaAuthGuard.canActivate(_route, _state);
        } else {
            if (!this.sessionService.checkIsLoggedInUser()) {
                this.router.navigate([routeUrl.login]);
                return false;
            }
            return true;
        }
  }
}