export const modulename = {
  group: 'group',
  location: 'location',
  facilityReport: 'facility-report',
  logs: 'log',
  userRole: 'user-role',
  user: 'user',
  userAccess: 'user-access',
  roster: 'roster',
  patient: 'patients',
  settingsTab: 'settings-tabs',
  reportTab: 'report-tabs',
  bedboard: 'bedboard',
  layout: 'layout',
  loginLayout: 'login-layout',
  login: 'login',
  resetPassword: 'reset-password',
  forgotPassword: 'forgot-password',
  move: 'move',
  filter: 'filters',
  themes: 'themes',
  search: 'search',
  addUserRole: 'add-user-role',
  editUserRole: 'edit-user-role',
  userRoleGrid: 'user-role-grid',
  addGroup: 'add-group',
  editGroup: 'edit-group',
  groupGrid: 'group-grid',
  addLocation: 'add-location',
  editLocation: 'edit-location',
  locationGrid: 'location-grid',
  addUser: 'add-user',
  editUser: 'edit-user',
  userGrid: 'user-grid',
  addPatient: 'add-patient',
  editPatient: 'edit-patient',
  patientGrid: 'patient-grid',
  addUserAccess: 'add-user-access',
  editUserAccess: 'edit-user-access',
  userAccessGrid: 'user-access-grid',
  addRoster: 'add-roster',
  editRoster: 'edit-roster',
  rosterGrid: 'roster-grid',
  automationLogs: 'automation-log',
  cohort: 'cohort',
  editCohort: 'edit-cohort',
  cohortGrid: 'cohort-grid',
  prioritizedView: 'prioritized-view',
  geomapView: 'geomap-view',
  filterView: 'Filter View',
  patientSearch: 'patient-search',
  logout: 'logout',
  postLogout: 'post-logout',
  userOnboard: 'user-onboard',
  validateUser: 'validate-user',
  patientBulkUpload: 'patient-bulk-upload',
  userProfile: 'user-profile'
};

export const titleName = {
  group: 'Group Management',
  location: 'Location Management',
  coreoView: 'Coreo View',
  bedboard: 'Bedboard View',
  prioritizedView: 'Priortized View',
  geomapView: 'Geomap View',
  userRole: 'User Role Management',
  user: 'User Management',
  userAccess: 'User Access Management',
  roster: 'User Roster Management',
  cohort: 'Patient Cohort Management',
  logs: 'Audit Trail Reports',
  automationLogs: 'Automation Log',
  patient: 'Coreo Population Search',
  bmPopup: 'Patient Summary',
  themes: 'Themes',
  filters: 'Filters',
  move: 'Patient Move',
  community: 'Community Details',
  facilityReport: 'Facility Reports',
};

export const routeUrl = {
  bedboard: '/' + modulename.layout + '/' + modulename.bedboard + '/',
  prioritizedView: '/' + modulename.layout + '/' + modulename.prioritizedView,
  geomapView: '/' + modulename.layout + '/' + modulename.geomapView,
  patient: '/' + modulename.layout + '/' + modulename.patient + '/',
  addPatient: '/' + modulename.layout + '/' + modulename.patient + '/' + modulename.addPatient + '/',
  patientSearch: '/' + modulename.layout + '/' + modulename.patientSearch + '/',
  report: '/' + modulename.layout + '/' + modulename.reportTab + '/',
  setting: '/' + modulename.layout + '/' + modulename.settingsTab + '/',
  userRole: '/' + modulename.layout + '/' + modulename.settingsTab + '/' + modulename.userRole + '/',
  userAccess: '/' + modulename.layout + '/' + modulename.settingsTab + '/' + modulename.userAccess + '/',
  group: '/' + modulename.layout + '/' + modulename.settingsTab + '/' + modulename.group + '/',
  location: '/' + modulename.layout + '/' + modulename.settingsTab + '/' + modulename.location + '/',
  user: '/' + modulename.layout + '/' + modulename.settingsTab + '/' + modulename.user + '/',
  login: '/' + modulename.loginLayout + '/' + modulename.login + '/',
  facilityReport: '/' + modulename.layout + '/' + modulename.reportTab + '/' + modulename.facilityReport + '/',
  logs: '/' + modulename.layout + '/' + modulename.reportTab + '/' + modulename.logs + '/',
  automationLogs: '/' + modulename.layout + '/' + modulename.reportTab + '/' + modulename.automationLogs + '/',
  resetPassword: '/' + modulename.loginLayout + '/' + modulename.resetPassword + '/',
  forgotPassword: '/' + modulename.loginLayout + '/' + modulename.forgotPassword + '/',
  roster: '/' + modulename.layout + '/' + modulename.settingsTab + '/' + modulename.roster + '/',
  cohort: '/' + modulename.layout + '/' + modulename.settingsTab + '/' + modulename.cohort + '/',
  logout: '/' + modulename.logout,
  postLogout: '/' + modulename.postLogout,
  userOnboard: '/' + modulename.userOnboard,
  validateUser: '/' + modulename.userGrid + '/' + modulename.validateUser,
  userProfile: '/' + modulename.layout + '/' + modulename.userProfile + '/',
};
