import {
  Injectable
} from '@angular/core';
import * as CryptoJS from 'crypto-js';

@Injectable()
export class CryptoService {
  private pvtk = 'b8b3c9354d324d27b5823156ec4627b3';

  constructor() { }

  decrypt(value: any) {
   const _key = CryptoJS.enc.Utf8.parse(this.pvtk);
   const _config = { mode: CryptoJS.mode.ECB };
   return CryptoJS.AES.decrypt(value, _key, _config).toString(CryptoJS.enc.Utf8);
  }
}
