import { BrowserModule, Title } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER, Injector } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { OktaAuthModule, OKTA_CONFIG } from '@okta/okta-angular';
import { OktaAuth } from '@okta/okta-auth-js';
import { ModalModule } from 'ngx-bootstrap/modal';
import { NgSelectModule } from '@ng-select/ng-select';
import { RouterModule } from '@angular/router';

import { HttpServiceModule } from './shared/modules/http.module';
import { AppRoutingModule } from './app-routing.module';
import { ErrorHandlingModule } from './error-handler.module';

import { AppComponent } from './app.component';
import { LogoutComponent } from './shared/components/logout.component';
import { PostLogoutComponent } from './shared/components/post-logout.component';
import { DataService } from './shared/services/data.service';
import { SessionService } from './shared/services/session.service';

import { PreInitService, preInitServiceFactory } from './pre-init.service';
// import { OktaConfigProvider } from './okta-config.provider';
import { CryptoService } from './shared/services/crypto.service';
import { AuthGuard } from './shared/services/auth-guard.service';
import { TooltipModule } from 'ngx-bootstrap/tooltip';

import { MyDefaultTooltipOptions } from './shared/components/ng2-tooltip.component';

// const oktaAuth = new OktaAuth({
//     clientId: "0oax6orsu09nldgvX0h7", // "dev- 0oawky4aqxhKEnw4E0h7", // "0oawl10kz6fJMLIw40h7", // QA1- 0oax6orsu09nldgvX0h7
//     issuer: "https://navvis.oktapreview.com/oauth2/default",
//    // redirectUri: "http://localhost:4200/implicit/callback",
//     redirectUri: "https://cvqa1.coreoflowsandbox.com/implicit/callback"
//   });

  // const oktaAuth = new OktaAuth({
  //   clientId: "0oawky4aqxhKEnw4E0h7", // "dev- 0oawky4aqxhKEnw4E0h7", // "0oawl10kz6fJMLIw40h7", // QA1- 0oax6orsu09nldgvX0h7
  //   issuer: "https://navvis.oktapreview.com/oauth2/default",
  //   redirectUri: "http://localhost:4200/implicit/callback",
  //   // redirectUri: "https://cvqa1.coreoflowsandbox.com/implicit/callback"
  // });

  // Build to CV - FMC
  const oktaAuth = new OktaAuth({
    "clientId": "0oa45qv2wxkyQZlY6297", // "iwk40DR5ZtqyF3VtAbnaNiLz2JSvlf6u+YiStJN5GR4=",
    "issuer": "https://login.coreohealth.com/oauth2/default",
    "redirectUri": "https://fmc-cv.coreohealth.com/implicit/callback"
  });

// Build to CV - SSM
// const oktaAuth = new OktaAuth({
//   "clientId": "0oa2hfn942av89Bi1297", // "1fwaxt4eWqcew0StSaDO2hEwAstOYqiHyJAFBdewRtI=",
//   "issuer": "https://login.coreohealth.com/oauth2/default",
//   "redirectUri": "https://ssm-cv.coreohealth.com/implicit/callback"
// });

// Build to CV - Allina
// const oktaAuth = new OktaAuth({
//  "clientId": "0oairlwrp3KLLQu8X297", // "adXCyqgIWPlwjvxL/J51L1zsK/vSInJJuRmbaeR0CfM=",
//   "issuer": "https://login.coreohealth.com/oauth2/default",
//   "redirectUri": "https://allina-cv.coreohealth.com/implicit/callback"
// });

@NgModule({
  declarations: [AppComponent, LogoutComponent, PostLogoutComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ErrorHandlingModule,
    BrowserAnimationsModule,
    FormsModule,
    RouterModule,
    CommonModule,
    TooltipModule.forRoot(),
    OktaAuthModule.forRoot({ oktaAuth }),
    ModalModule.forRoot(),
    HttpServiceModule
  ],
  providers: [DataService, SessionService, PreInitService,
  {
    provide: APP_INITIALIZER,
    useFactory: preInitServiceFactory,
    deps: [PreInitService, Injector],
    multi: true
  },
   CryptoService, Title, AuthGuard],
  bootstrap: [AppComponent]
})
export class AppModule { }