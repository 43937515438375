import { Component, OnInit, Inject, ViewChild, OnDestroy, HostListener } from '@angular/core';
import { Router, NavigationStart, NavigationEnd, NavigationError, RouterOutlet } from '@angular/router';
import { OktaAuthStateService, OKTA_AUTH } from '@okta/okta-angular';
import { AuthState, OktaAuth } from '@okta/okta-auth-js';
import {
  BsModalRef,
  BsModalService
} from 'ngx-bootstrap/modal';
import { TemplateRef } from '@angular/core';
import { DataService } from './shared/services/data.service';
import { SessionService } from './shared/services/session.service';
import * as SignalR from '@aspnet/signalr';
import { MessagePackHubProtocol } from '@aspnet/signalr-protocol-msgpack';
import { POST_MSG, APPLICATION_CONFIGURATION } from './shared/config/config';
import * as moment from 'moment';
import { modulename, routeUrl } from './shared/config/modules';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.less'],
})
export class AppComponent implements OnInit, OnDestroy {
  private hubConnection: SignalR.HubConnection;
  isLoading: boolean;
  getAppAccessSub: any;
  getUserInvalidSub: any;
  getUnknownHttpResponseSub: any;
  getApiErrorMessageShowSub: any;
  timerSet: boolean;
  timeOut: any;
  showApiErrorMessagePopup: boolean;

  @ViewChild('loadModal') loadModal: TemplateRef<any>;
  @ViewChild('accessModal') accessModal: TemplateRef<any>;
  @ViewChild('responseErrorModal') responseErrorModal: TemplateRef<any>;
  @ViewChild('apiErrorMessageShowModal') apiErrorMessageShowModal: TemplateRef<any>;
  loadModalRef: BsModalRef;
  accessModalRef: BsModalRef;
  responseErrorModalRef: BsModalRef;
  apiErrorMessageShowModalRef: BsModalRef;

  constructor(
    public dataService: DataService,
    public oktaAuth: OktaAuthStateService,
    @Inject(OKTA_AUTH) private _oktaAuth: OktaAuth,
    private router: Router,
    private sessionService: SessionService,
    private modalService: BsModalService
  ) {
    this.timerSet = false;
    this.timeOut = null;
    this.showApiErrorMessagePopup = true;
  }

  async ngOnInit() {
    this.getLoginType();
    this.showApiErrorMessagePopup = APPLICATION_CONFIGURATION.CONFIG.showApiErrorMessagePopup;
    this.router.events.subscribe((event: any) => {
      switch (true) {
        case event instanceof NavigationStart: {
          if (
            this.router.url.indexOf('implicit') !== -1 ||
            this.router.url === '/'
          ) {
            setTimeout(() => {
              const div = document.getElementsByTagName('div');
              if (div && div[0] && div[0].innerText.startsWith('AuthSdkError')) {
                console.error(div[0].innerText);
                window.location.href = window.location.origin;
              }
            }, 1000);
            // this.modal.open();
            // this.loadModalRef = this.modalService.show(this.loadModal, {
            //   class: 'modal-dialog modal-lg initial-loader bg-none',
            //   backdrop: "static",
            //   id: "initial-whitebkgnd",
            //   keyboard: false,
            // });
          }
          break;
        }
        case event instanceof NavigationEnd: {
          if (
            this.router.url.indexOf('implicit') === -1 &&
            this.router.url !== '/'
          ) {
            setTimeout(() => {
              this.loadModalRef?.hide();
            }, 6000);
          }
          break;
        }
        case event instanceof NavigationError: {
          this.checkChunkError(event as NavigationError);
          break;
        }
        default: {
          break;
        }
      }
    });

    this.hubConnection = new SignalR.HubConnectionBuilder()
      .withUrl(APPLICATION_CONFIGURATION.CONFIG.baseUrl + 'signalr', {
        skipNegotiation: true,
        transport: SignalR.HttpTransportType.WebSockets
      })
      .withHubProtocol(new MessagePackHubProtocol())
      .configureLogging(SignalR.LogLevel.None)
      .build();

    this.hubConnection
      .start()
      .then(() => { })
      .catch(err => console.error(err));

    this.hubConnection.onclose(e => {
      setTimeout(() => {
        this.hubConnection
          .start()
          .then(() => { })
          .catch(err => console.error(err));
      }, 3000);
    });

    this.hubConnection.on('UpdateBedBoard1', data => {
      if (data) {
        this.dataService.updateSignalrTrigger(data);
      }
    });

    this.getAppAccessSub = this.dataService
      .getAppAccess()
      .subscribe((data: boolean) => {
        if (data) {
          this.loadModalRef?.hide();
          this.accessModalRef = this.modalService.show(this.accessModal, {
            class: 'modal-dialog modal-lg',
            backdrop: "static",
            keyboard: false,
          });
        }
      });

    this.getUserInvalidSub = this.dataService
      .getUserInvalid()
      .subscribe((data: boolean) => {
        if (data) {
          this.userLogout();
        }
      });
    this.getUnknownHttpResponseSub = this.dataService
      .getUnknownHttpResponse()
      .subscribe(() => {
        this.responseErrorModalRef = this.modalService.show(this.responseErrorModal, {
          class: 'modal-dialog modal-lg',
          backdrop: "static",
          keyboard: false,
        });
      });
    this.getApiErrorMessageShowSub = this.dataService
    .getApiErrorMessageShow()
    .subscribe(() => {
      this.apiErrorMessageShowModalRef = this.modalService.show(this.apiErrorMessageShowModal, {
        class: 'modal-dialog modal-lg api-error-message',
        backdrop: "static",
        keyboard: false,
      });
      this.timeOut = setTimeout(() => {
        this.apiErrorMessageShowModalRef?.hide()
      }, 5000)
    })
  }

  ngOnDestroy() {
    this.getAppAccessSub.unsubscribe();
    this.getUserInvalidSub.unsubscribe();
    this.getUnknownHttpResponseSub.unsubscribe();
    this.getApiErrorMessageShowSub.unsubscribe();
    this.removeTimeOut();
  }

  checkChunkError(event: NavigationError) {
    const chunkFailedMessage = /Loading chunk [\d]+ failed/;
    if (chunkFailedMessage.test(event.error.message)) {
      window.location.href = `${window.location.origin}${event.url}`;
    }
  }

  logout() {
    this.accessModalRef?.hide();
    this.dataService.updateLogoutTrigger();
    this.loadModalRef = this.modalService.show(this.loadModal, {
      class: 'modal-dialog modal-lg initial-loader bg-none',
      backdrop: "static",
      id: "initial-whitebkgnd",
      keyboard: false,
    });
  }

  removeTimeOut() {
    if (this.timeOut) {
      clearTimeout(this.timeOut);
    }
  }

  clickOk() {
    this.apiErrorMessageShowModalRef?.hide();
    this.removeTimeOut();
  }

  userLogout() {
    this.router.navigate([routeUrl.postLogout]);
  }

  @HostListener('document:keyup', ['$event'])
  @HostListener('document:click', ['$event'])
  @HostListener('document:wheel', ['$event'])
  resetTimer () {
    this.sessionService.lastInteractedTime = moment.utc();
    if (!this.timerSet && this.sessionService.isExistInUEE) {
      this.timerSet = true;
      setTimeout(() => {
        window.parent.postMessage({ type: POST_MSG.EXTEND_SESSION }, '*');
        this.timerSet = false;
      }, 60000);
    }
  }

  getLoginType() {
    this.sessionService.isOktaLogin = !APPLICATION_CONFIGURATION.CONFIG.isCustomAuthentication;
    this.sessionService.getCurrentSession();
    this.sessionService.getIsOktaLogin().subscribe(
      (res: any) => {
      },
      error => {
        throw {
          Error: error,
          Message: 'Login Type Configuration Failed'
        };
      }
    );
  }
}
