import { LOCATION_INITIALIZED } from '@angular/common';
import { HttpBackend, HttpClient } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';

import { AppConfigService, IAppConfig } from './shared/services/appconfig.service';

export function preInitServiceFactory(
  initService: PreInitService,
  injector: Injector,
) {
  return () =>
    new Promise(resolve =>
      injector
        .get(LOCATION_INITIALIZED, Promise.resolve(undefined))
        .then(() => resolve(initService.onInit())),
    );
}

@Injectable()
export class PreInitService {

  constructor(
    private httpBackend: HttpBackend,
    private appConfigService: AppConfigService,
  ) {}

  onInit(): Promise<boolean> {
    const http = new HttpClient(this.httpBackend);
    return http
      .get<IAppConfig>('../appsettings.json')
      .toPromise()
      .then(config => this.appConfigService.setConfig(config))
      .then(_ => true)
      .catch(error => {
        console.error('Error loading application', error);
        return Promise.resolve(false);
      });
  }
}
